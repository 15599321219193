import React, { useState, useEffect } from "react";
import axios from "../axios";
import delete_row_img from '../Images/icons/x.png';

function Contact_details_table_rows({rowsData1, handleContactChange, instanceNumber1, colorFocus1, ContactValidate,setContactDetailsFlag, colorFocus2, colorFocus3, colorFocus4, RelationValidate, FirstNameValidate, LastNameValidate, setContactRelationFlag, setFirstNameFlag, setLastNameFlag}) {
    
    const [ContactDetailsAll, setContactDetailsAll] = useState([]);
    const [RelationshipAll, setRelationshipAll] = useState([]);
    const [ContactTitleAll, setContactTitleAll] = useState([]);
    const client_id = localStorage.getItem("client_id");
    const instance_id = localStorage.getItem("instance_id");

    const [ContactDetId, setContactDetId] = useState(0);
    const [contact_details, setContactDetailsId] = useState(0);
    const [relationship, setrelationship] = useState(0);
    const [title, settitle] = useState(0);
    const [firstname, setfirstname] = useState('');
    const [surname, setsurname] = useState('');
    const [home_phone, sethome_phone] = useState('');
    const [work_phone, setwork_phone] = useState('');
    const [physical_address, setphysical_address] = useState('');
    const [employer_name, setemployer_name] = useState('');
    const [department, setdepartment] = useState('');
    const [relationFlag, setrelationFlag] = useState(0);
    const [TitleFlag, setTitleFlag] = useState(0);
    const WesBankTabId = localStorage.getItem("WesBankTabId");

    //----------- get client details -----------------
    useEffect(() => { 
        if(WesBankTabId === '67'){
            const payload = {
                client_id: client_id,
                instance_id: instance_id,
            };    
            axios
            .post("finance_application/fetach_contact_details_dropdown",payload)
            .then((response) => {
                //console.log(response);
                if(response.status === 200){
                    setContactDetailsAll(response.data.data.main_array)
                }
            })
            .catch(() => {
                console.log('error');
            });
        }
    }, [WesBankTabId]); 

    //----------- get salary date / relationship (employee details) -----------------
    useEffect(() => {   
        if(WesBankTabId && (WesBankTabId === '47' || WesBankTabId === '67')) {  
            axios.get("finance_application/featch_salary_date_relationship")
            .then((response) => {
                if(response.status === 200){
                    setRelationshipAll(response.data.data.relationship);
                } 
            })
            .catch(() => {
                console.log('error');
            });
        }
    }, [WesBankTabId]);

    //----------- get client title -----------------
    useEffect(() => {     
        if(WesBankTabId === '45'){
            axios.get("add_edit_company/featch_titles")
            .then((response) => {
            // console.log(response.data.data.customer_type_array);
                if(response.status === 200){
                setContactTitleAll(response.data.data.title_array);
                }
            })
            .catch(() => {
                console.log('error');
            });
        }
    }, [WesBankTabId]);

    const handleContactDet = (index, evnt)=>{   // edit checkbox array
        
        let condetid = evnt.target.value;
        setContactDetId(condetid);
        //console.log(condet); 
       // setContactDetailsFlag(condetid) 
    }

    const handleRelation = ()=>{
        setrelationFlag(1);
    }

    const handleTitle = ()=>{
        setTitleFlag(1);
    }

    const OnContactWiseChange = (typeContactId) => {
        // console.log(typeContactId);
         if(typeContactId == 0){
            setContactDetailsId(0); setrelationship("0"); settitle("0"); setfirstname(""); 
            setsurname(""); sethome_phone(""); setwork_phone("");
            setphysical_address(""); setemployer_name(""); setdepartment(""); 
            setrelationFlag(0); setTitleFlag(0)
            }else{
                 const payload = {
                    contact_det_id: typeContactId,
                 };
            
                axios
                 .post("finance_application/featch_contact_details_wise",payload)
                 .then((response) => {
                    //console.log(response);
                    if(response.status === 200 && response.data.data != ''){
                        setContactDetailsId(response.data.data.contact_details);
                        setrelationship(response.data.data.relationship);
                        settitle(response.data.data.title);
                        setfirstname(response.data.data.firstname);
                        setsurname(response.data.data.surname);
                        sethome_phone(response.data.data.home_phone);
                        setwork_phone(response.data.data.work_phone);
                        setphysical_address(response.data.data.physical_address);
                        setemployer_name(response.data.data.employer_name);
                        setdepartment(response.data.data.department); 

                        if(response.data.data.firstname != '' || response.data.data.surname != '' || response.data.data.relationship != 0){
                            setFirstNameFlag(0);
                            setLastNameFlag(0);  
                            setContactRelationFlag(0);              
                        }else{
                            setFirstNameFlag(1); 
                            setLastNameFlag(1);                  
                            setContactRelationFlag(1);    
                        }
                    } 
                 })
                 .catch(() => {
                    console.log('error');
                 });
        }
    }
    

    return(
        
        rowsData1.map((data, index)=>{
            // console.log(data);
            // console.log(index);
            const {contact_details1,relationship1,title1,firstname1,surname1,home_phone1,work_phone1,physical_address1,employer_name1,department1}= data;
            
            const final_instance_number = instanceNumber1+index+1;                                
            const contact_details_id = `sub_${final_instance_number}_field_967`;
            const contact_relationship = `sub_${final_instance_number}_field_968`;
            const contact_title = `sub_${final_instance_number}_field_969`;
            const contact_firstname = `sub_${final_instance_number}_field_970`;
            const contact_surname = `sub_${final_instance_number}_field_971`;
            const contact_home_phone = `sub_${final_instance_number}_field_972`;
            const contact_work_phone = `sub_${final_instance_number}_field_973`;
            const contact_physical_address = `sub_${final_instance_number}_field_976`;
            const contact_employer_name = `sub_${final_instance_number}_field_974`;
            const contact_department = `sub_${final_instance_number}_field_975`;
            //console.log(RelationValidate);
            return(
                <tr key={index}>
                <td style={{ textAlign: "center" }}>
                    <select id="contact_details" name={contact_details_id} className="text-uppercase" value={contact_details} onChange={(evnt) => { handleContactChange(index, evnt, contact_details_id,final_instance_number); handleContactDet(index, evnt); OnContactWiseChange(evnt.target.value); setContactDetailsFlag(0)}} style={contact_details_id == ContactValidate ? {borderColor: colorFocus1} : null }>
                            <option value="0">-- Select an option --</option>
                            {ContactDetailsAll != null ?
                                ContactDetailsAll.map((item) => {
                                    return (
                                    <option key={item.id} value={item.id}>
                                    {item.name}
                                    </option>
                                    );
                                })
                            : null }
                    </select>
                </td>

                <td style={{ textAlign: "center" }}>
                    {  relationship != '' && relationFlag === 0 ?
                        <select id="relationship" name={contact_relationship} className="text-uppercase" value={relationship} onChange={(evnt) => { handleContactChange(index, evnt, contact_details_id); handleRelation(); setContactRelationFlag(0) }} style={contact_relationship == RelationValidate ? {borderColor: colorFocus2} : null }>
                                <option value="0">-- Select an option --</option>
                                {RelationshipAll.map((item) => {
                                    return (
                                    <option key={item.id} value={item.id}>
                                    {item.relation}
                                    </option>
                                    );
                                })}
                        </select>
                    :
                        <select id="relationship" name={contact_relationship} className="text-uppercase" defaultValue={relationship1} onChange={(evnt) => { handleContactChange(index, evnt, contact_details_id, contact_relationship); setContactRelationFlag(0); }} style={contact_relationship == RelationValidate ? {borderColor: colorFocus2} : null }>
                                <option value="0">-- Select an option --</option>
                                {RelationshipAll.map((item) => {
                                    return (
                                    <option key={item.id} value={item.id}>
                                    {item.relation}
                                    </option>
                                    );
                                })}
                        </select>
                    }
                </td>

                <td style={{ textAlign: "center" }}>
                    
                    {  title != '' && TitleFlag === 0 ?
                        <select id="title" name={contact_title} className="text-uppercase" value={title} onChange={(evnt) => { handleContactChange(index, evnt, contact_details_id); handleTitle(); }} >
                            <option value="0">-- Select an option --</option>
                            {ContactTitleAll.map((item) => {
                                return (
                                <option key={item.id} value={item.id}>
                                {item.title}
                                </option>
                                );
                            })}
                        </select>
                    :
                        <select id="title" name={contact_title} className="text-uppercase" defaultValue={title1} onChange={(evnt) => { handleContactChange(index, evnt, contact_details_id); }} >
                            <option value="0">-- Select an option --</option>
                            {ContactTitleAll.map((item) => {
                                return (
                                <option key={item.id} value={item.id}>
                                {item.title}
                                </option>
                                );
                            })}
                        </select>
                    }
                </td>
   {/* style={{ textTransform: "uppercase" }} */}
                <td style={{ textAlign: "center" }}>
                    <input type="text" id="firstname" className="text-uppercase" name={contact_firstname} defaultValue={firstname != '' ? firstname : firstname1} onChange={(evnt) => { handleContactChange(index, evnt, contact_details_id); setFirstNameFlag(0); }} style={contact_firstname == FirstNameValidate ? {borderColor: colorFocus3} : null } />
                </td>   

                <td style={{ textAlign: "center" }}>
                    <input type="text" id="surname" className="text-uppercase" name={contact_surname} defaultValue={surname != '' ? surname : surname1} onChange={(evnt) => { handleContactChange(index, evnt, contact_details_id); setLastNameFlag(0); }} style={contact_surname == LastNameValidate ? {borderColor: colorFocus4} : null } />
                </td>

                <td style={{ textAlign: "center" }}>
                    <input type="text" id="home_phone" name={contact_home_phone} defaultValue={home_phone != '' ? home_phone : home_phone1} onChange={(evnt) => { handleContactChange(index, evnt, contact_details_id); }} style={{ textTransform: "uppercase" }} />
                </td>

                <td style={{ textAlign: "center" }}>
                    <input type="text" id="work_phone" name={contact_work_phone} defaultValue={work_phone != '' ? work_phone : work_phone1} onChange={(evnt) => { handleContactChange(index, evnt, contact_details_id); }} style={{ textTransform: "uppercase" }} />
                </td>

                <td style={{ textAlign: "center" }}>
                    {physical_address != '' ? 
                        <textarea rows="4" id="physical_address" name={contact_physical_address} style={{ textTransform: "uppercase" }} defaultValue={physical_address} onChange={(evnt) => { handleContactChange(index, evnt, contact_details_id); }} />
                    :
                        <textarea rows="4" id="physical_address" name={contact_physical_address} style={{ textTransform: "uppercase" }} defaultValue={physical_address} onChange={(evnt) => { handleContactChange(index, evnt,contact_details_id); }} />
                    }
                   
                </td>

                <td style={{ textAlign: "center" }}>
                    <input type="text" id="employer_name" name={contact_employer_name} defaultValue={employer_name != '' ? employer_name : employer_name1} onChange={(evnt) => { handleContactChange(index, evnt,contact_details_id); }} style={{ textTransform: "uppercase" }} />
                </td>

                <td style={{ textAlign: "center" }}>
                    <input type="text" id="department" name={contact_department} defaultValue={department != '' ? department : department1} onChange={(evnt) => { handleContactChange(index, evnt, contact_details_id); }} style={{ textTransform: "uppercase" }} />
                </td>

                <td nowrap="nowrap" className="text-center">
                     {/* <input type="image" name="delete_file" src={delete_row_img} width="16" onClick={() => { if (window.confirm('Are you sure you want to delete this row?')) { } }}/> */}
                </td>
            </tr>
            )
        })
   
    )
    
}
export default Contact_details_table_rows;