import React, { useEffect, useState } from "react";
import Login from "./Authorization/Login";
import { Routes, Route } from "react-router-dom";
import { token } from "./features/TokenSlice";
import { useDispatch, useSelector } from "react-redux";
import './App.css';
//----------------- main componets ------------
import Template from "./Components/Template";
import Home from "./Dashboard/Home";
//import Footer from "./Dashboard/Footer";
import Admin from "./Admin/Admin";
import Admin_menu from "./Admin/Admin_menu";
import Add_user from "./Admin/Add_user/Add_user";
import User_rights from "./Admin/Add_user/User_rights";
import Branch_access from "./Admin/Add_user/Branch_access";
import User_search from "./Admin/Search_user/User_search";
import Profile from "./Admin/Search_user/Profile";
import Loanhouses_list from "./Admin/Finance_institution/Loanhouses_list";
import Loanhouses from "./Admin/Finance_institution/Loanhouses";
import Loanhouses_edit from "./Admin/Finance_institution/Loanhouses_edit";
import Assign_loanhouse from "./Admin/Finance_institution/Assign_loanhouse";
import Profile_history from "./Admin/Search_user/Profile_history";
import Search_new from "./Search/Search_new";
import Create_new from "./Search/Create_new";
import Create_transaction from "./Transaction/Create_transaction";
import Transaction_menu from "./Transaction/Transaction_menu";
import Fill_forms from "./Transaction/Fill_forms";
import Edit_transcation from "./Transaction/Edit_transcation";
import Client_company_add_edit from "./Transaction/Client_company_add_edit";
import Client_company_view from "./Transaction/Client_company_view";
//-----------Products
import Add_accessories_to_transaction from "./Products/Add_accessories_to_transaction";
import Add_to_transaction from "./Products/Add_to_transaction";
import Products from "./Transaction/Products";
import Insurance_products from "./Transaction/Insurance_products";
import View_non_insurance_product from "./Transaction/View_non_insurance_product";
import View_insurance_products from "./Transaction/View_insurance_products";
import Search from "./Products/Search";
import Record_of_advice from "./Transaction/Record_of_advice";
import Record_pdf from "./Transaction/Record_pdf";
import Add_Person from "./Transaction/Add_Person";
import Comp_insurance_discount from "./Transaction/Comp_insurance_discount";

import Affiliates from "./Admin/Group_admin/Affiliates";
import Group_fsp from "./Admin/Group_admin/Group_fsp";
import Affiliates_add from "./Admin/Group_admin/Affiliates_add";
import Group_defaults from "./Admin/Group_admin/Group_defaults";
import Group_defaults_edit from "./Admin/Group_admin/Group_defaults_edit";
import Group_defaults_add from "./Admin/Group_admin/Group_defaults_add";
import Branch_settings from "./Admin/Group_admin/Branch_settings";
import Branch_settings_edit from "./Admin/Group_admin/Branch_settings_edit";
import Branch_defaults from "./Admin/Group_admin/Branch_defaults";
import Apl_index from "./Admin/Group_admin/Apl_index"
import Branch_receipts from "./Admin/Group_admin/Branch_receipts";
import Branch_details from "./Admin/Group_admin/Branch_details";
import Company_add_edit from "./Admin/Add_company/Company_add_edit";
import Company_edit from "./Admin/Add_company/Company_edit";

import Roles from "./Admin/Manage_roles/Roles";
import Company_view_btn from "./Admin/Add_company/Company_view_btn";
import Role_settings from "./Admin/Manage_roles/Role_settings";
import Setup_prime_interest from "./Admin/Setup/Setup_prime_interest";
import Edit_prime_interest from "./Admin/Setup/Edit_prime_interest";

import VechileAccessories_menu from "./Admin/Vechile&Accessories/VechileAccessories_menu";
import Search_products from "./Admin/Vechile&Accessories/Search_products";
import Modify_product from "./Admin/Vechile&Accessories/Modify_product";
import Attributes_capture from "./Admin/Vechile&Accessories/Attributes_capture";
import Sub_products from "./Admin/Vechile&Accessories/Sub_products";
import Import from "./Admin/Vechile&Accessories/Import";
import Import_mm_codes from "./Admin/Vechile&Accessories/Import_mm_codes";
import Create_product from "./Admin/Vechile&Accessories/Create_product";
import Transaction_products from "./Admin/Product_admin/Transaction_products";
import Financial_products from "./Admin/Product_admin/Financial_products";
import Categories from "./Admin/Product_admin/Categories";
import Option_linking from "./Admin/Product_admin/Option_linking";
import Option_linking_edit from "./Admin/Product_admin/Option_linking_edit";
import Add_product from "./Admin/Product_admin/Add_product";
import Product_options from "./Admin/Product_admin/Product_options";
import List_categories from "./Admin/Product_admin/List_categories";
import Accessories from "./Admin/Vechile&Accessories/Accessories";
import Add_edit_accessories from "./Admin/Vechile&Accessories/Add_edit_accessories";
import Accessory_admin from "./Admin/Vechile&Accessories/Accessory_admin";
import Add_accessory_to_product from "./Admin/Vechile&Accessories/Add_accessory_to_product";
import Edit_product from "./Admin/Product_admin/Edit_product";
import Inception from "./Transaction/Inception";
import Report_folder from "./Report/Report_folder";
import Finance_application from "./Transaction/Finance_application";  // wes bank
import Finance_application_standbic from "./Transaction/Finance_application_standbic"; // standbic bank
import Finance_application_ford_finance from "./Transaction/Finance_application_ford_finance"; // ford finance bank
import Finance_application_bank_garborone from "./Transaction/Finance_application_bank_garborone"; // bank garborone
import Finance_application_bank_absa from "./Transaction/Finance_application_bank_absa"; // bank absa
import Finance_application_bostwana_building from "./Transaction/Finance_application_bostwana_building"; // bostwana building society
import Finance_application_capital_baroda_standard_bank from "./Transaction/Finance_application_capital_baroda_standard_bank"; // capital / baroda / standard charted bank
import Prepare from "./Transaction/Prepare";
import Transaction_document from "./Transaction/Transaction_document";
import Report_individual from "./Report/Report_individual";
import Render_options from "./Report/Render_options";
import Search_company from "./Admin/Add_company/Search_company";
import Un_incept_transaction from "./Transaction/Un_incept_transaction";
import Audit from "./Admin/Audit_log/Audit";
import Loader from "./Components/Loader";

// Vechile&Accessories
function App() {
  const dispatch = useDispatch();
 //console.log('testkjhkj');
  useEffect(() => {
    if (localStorage.getItem("Token")) {
      dispatch(token(JSON.parse(localStorage.getItem("Token"))));
    }
  }, [dispatch]);

  const tokenValue = useSelector((state) => state.token);
  var paths = null;

  // console.log(localStorage.getItem("Token"));
  if (tokenValue.value.token || localStorage.getItem("Token")) {
    //console.warn('sdsdd')
    paths = (

      <Routes>
        <Route exact path="/home" element={<Home />}></Route>
        <Route exact path="/admin" element={<Admin />}></Route>
        <Route exact path="/admin_menu" element={<Admin_menu />}></Route>
        <Route exact path="/loader" element={<Loader />}></Route>

        {/* -------------------------------ADD USER/SEARCH USER------------------------------- */}
        <Route exact path="/admin/add_user/:copy" element={<Add_user />}></Route>
        <Route exact path="/admin/user_rights/:employee" element={<User_rights />} ></Route>
        <Route exact path="/admin/branch_access/:user_id" element={<Branch_access />} ></Route>
        <Route exact path="/admin/search/:group_id" element={<User_search />}></Route>

        {/* Products */}
        <Route exact path="/products/add_accessories_to_transaction/module=Transactions/:parent_id" element={<Add_accessories_to_transaction />}></Route>
        <Route exact path="/transaction/products/module=Transactions/category=2/:parent_id" element={<Products />}></Route>
        <Route exact path="/transaction/insurance_products/module=Transactions/category=1/:parent_id" element={<Insurance_products />}></Route>
        {/* view Products */}
        <Route exact path="/transaction/view_non_insurance_product/category=2/:parent_id" element={<View_non_insurance_product />}></Route>
        <Route exact path="/transaction/view_insurance_products/category=1/:parent_id" element={<View_insurance_products />}></Route>
        

        <Route exact path="/products/add_to_transaction/command=Edit/module=Transactions/:id/:tp_id" element={<Add_to_transaction />}></Route>
  
        <Route exact path="/products/add_to_transaction/module=Transactions/:tp_id" element={<Add_to_transaction />}></Route>
        <Route exact path="/products/search/product_type=vehicles/command=search/module=Transactions/products_product_type/:product_type_id" element={<Search />}></Route>
        <Route exact path="/transaction/comp_insurance_discount" element={<Comp_insurance_discount />}></Route>

        <Route exact path="/transaction/roa" element={<Record_of_advice />}></Route>
        <Route exact path="/transaction/pdf" element={<Record_pdf />}></Route>

        <Route exact path="/transaction/add_person" element={<Add_Person />}></Route>
        <Route exact path="/transaction/add_person/:id/person_id=0/cclient=1" element={<Add_Person />}></Route>
        <Route exact path="/transaction/add_person/:id/person_id/:person_id" element={<Add_Person />}></Route>

        {/* --------------------------------FINANCE INSTITUTION-------------------------------- */}
        <Route exact path="/admin/loanhouses_list" element={<Loanhouses_list />}></Route>
        <Route exact path="/search/search_new/:id" element={<Search_new />}></Route>
        <Route exact path="/search/create_new/:client_id/:old_transaction_id" element={<Create_new />}></Route>
        <Route exact path="/transaction/create_transaction/:id" element={<Create_transaction />}></Route>
        <Route exact path="/transaction/un_incept" element={<Un_incept_transaction />}></Route>
        <Route exact path="/transaction_menu" element={<Transaction_menu />}></Route>
        <Route exact path="/transaction/fill_forms" element={<Fill_forms />}></Route>
        <Route exact path="/transaction/edit_transaction/:id" element={<Edit_transcation />}></Route>
        <Route exact path="/transaction/client_company_add_edit/:id" element={<Client_company_add_edit />}></Route>
        <Route exact path="/transaction/client_company_view/:id" element={<Client_company_view />}></Route>

        <Route exact path="/admin/profile/:user_id" element={<Profile />}></Route>
        <Route exact path="/admin/profile_history/:user_id" element={<Profile_history />}></Route>
        <Route exact path="/admin/loanhouses/:loanhouse_id" element={<Loanhouses />}></Route>
        <Route exact path="/admin/loanhouses_edit/:loanhouse_id" element={<Loanhouses_edit />}></Route>
        <Route exact path="/admin/assign_loanhouse/:loanhouse_id" element={<Assign_loanhouse />}></Route>

        {/* --------------------------------GROUP ADMIN-------------------------------- */}
        <Route exact path="/admin/group_admin/affiliates" element={<Affiliates />}></Route>
        <Route exact path="/admin/group_admin/affiliates_add" element={<Affiliates_add />}></Route>
        <Route exact path="/admin/group_admin/group_fsp/:affiliate" element={<Group_fsp />}></Route>
        <Route exact path="/admin/group_admin/group_defaults/:division" element={<Group_defaults />}></Route>
        <Route exact path="/admin/group_admin/group_defaults_add/:affiliate" element={<Group_defaults_add />}></Route>
        <Route exact path="/admin/group_admin/group_defaults_edit/:affiliate/:division" element={<Group_defaults_edit />}></Route>
        <Route exact path="/admin/group_admin/add_branch/:affiliate/:division" element={<Branch_settings />}></Route>
        <Route exact path="/admin/group_admin/edit_branch/:division/:department" element={<Branch_settings_edit />}></Route>
        <Route exact path="/admin/group_admin/branch_defaults/:division/:department" element={<Branch_defaults />}></Route>
        <Route exact path="/admin/group_admin/apl_index/:department" element={<Apl_index />}></Route>
        <Route exact path="/admin/group_admin/branch_receipts/:department" element={<Branch_receipts />}></Route>
        <Route exact path="/admin/group_admin/branch_details/:division/:department" element={<Branch_details />}></Route>

        {/* --------------------------------ADD COMPANY-------------------------------- */}
        <Route exact path="/admin/search_company" element={<Search_company />}></Route>
        <Route exact path="/admin/company_add_edit" element={<Company_add_edit />}></Route>
        <Route exact path="/admin/add_company/edit/:companyId" element={<Company_edit />}></Route>
        <Route exact path="/admin/add_company/btn/:companyId" element={<Company_view_btn />}></Route>

        {/* --------------------------------MANAGE ROLES-------------------------------- */}
        <Route exact path="/admin/roles" element={<Roles />}></Route>
        <Route exact path="/admin/role_edit/:roleId" element={<Role_settings />}></Route>


        {/* ----------------------------------SETUP----------------------------------- */}
        <Route exact path="/admin/setup" element={<Setup_prime_interest />}></Route>
        <Route exact path="/admin/edit_setup/:countryId" element={<Edit_prime_interest />}></Route>

        {/* --------------------------VECHILE & ACCESSORIES----------------------------- */}
        <Route exact path="/admin/vechile_accessories_menu" element={<VechileAccessories_menu />}></Route>
        <Route exact path="/products/search/new_product" element={<Search_products />}></Route>
        <Route exact path="/products/accessories" element={<Accessories />}></Route>
        <Route exact path="/products/add_edit_accessories/:product_id" element={<Add_edit_accessories />}></Route>
        <Route exact path="/products/accessory_admin/:id/:template_id" element={<Accessory_admin />}></Route>
        <Route exact path="/products/add_accessory_to_product/:accessory_id" element={<Add_accessory_to_product />}></Route>
        <Route exact path="/product/modify_product/:productId" element={<Modify_product />}></Route>
        <Route exact path="/product/create" element={<Create_product />}></Route>
        <Route exact path="/product/attributes_capture" element={<Attributes_capture />}></Route>
        <Route exact path="/product/sub_products/:parent_id" element={<Sub_products />}></Route>
        <Route exact path="/import" element={<Import />}></Route>
        <Route exact path="/import_mm_codes/:template_id" element={<Import_mm_codes />}></Route>


        {/* -----------------------------PRODUCT ADMIN-------------------------------- */}
        <Route exact path="/admin/products" element={<Transaction_products />}></Route>
        <Route exact path="/admin/financial_products" element={<Financial_products />}></Route>
        <Route exact path="/admin/categories/:parentId" element={<Categories />}></Route>
        <Route exact path="/admin/list_categories/:option_id" element={<List_categories />}></Route>
        <Route exact path="/admin/option_linking/:listParent/:productId" element={<Option_linking />}></Route>
        <Route exact path="/option_linking/edit/:productId/:linkId" element={<Option_linking_edit />}></Route>
        <Route exact path="/admin/add_product" element={<Add_product />}></Route>
        <Route exact path="/admin/edit_product" element={<Edit_product />}></Route>
        <Route exact path="/admin/product_options/:listParent/:productId" element={<Product_options />}></Route>


        {/* ------------------------------TRANSACTION (SHARDA)------------------------------ */}
        <Route exact path="/transaction/inception" element={<Inception />}></Route>

        {/* ------------------------------REPORTS (SHARDA)------------------------------ */}
        <Route exact path="/reports/folders" element={<Report_folder />}></Route>
        <Route exact path="/reports/individual/:FolderId" element={<Report_individual />}></Route>
        <Route exact path="/reports/render_options/:FolderId/:ReportId" element={<Render_options />}></Route>

        {/* ------------------------------AUDIT LOG (SHARDA)------------------------------ */}
        <Route exact path="/admin/audit" element={<Audit />}></Route>

        <Route exact path="/transaction/finance_application" element={<Finance_application />}></Route>
        <Route exact path="/transaction/prepare/application_id/:ap_id/regenerated=true" element={<Prepare />}></Route>
        <Route exact path="/transaction/doc" element={<Transaction_document />}></Route>
        {/* 
        <Route path="*" element={<Template><Home /></Template>}></Route> */}
      
      <Route exact path="/transaction/finance_application" element={<Finance_application/>}></Route>
      <Route exact path="/transaction/finance_application_standbic" element={<Finance_application_standbic/>}></Route>
      <Route exact path="/transaction/finance_application_ford_finance" element={<Finance_application_ford_finance/>}></Route>
      <Route exact path="/transaction/finance_application_bank_garborone" element={<Finance_application_bank_garborone/>}></Route> 
      <Route exact path="/transaction/finance_application_bank_absa" element={<Finance_application_bank_absa/>}></Route> 
      <Route exact path="/transaction/finance_application_bostwana_building" element={<Finance_application_bostwana_building/>}></Route> 
      <Route exact path="/transaction/finance_application_capital_baroda_standard_bank" element={<Finance_application_capital_baroda_standard_bank/>}></Route> 
      
      <Route exact path="/transaction/prepare/application_id=:ap_id&regenerated=true" element={<Prepare/>}></Route>
      <Route exact path="/transaction/doc" element={<Transaction_document/>}></Route>
      <Route path="*" element={<Template><Home /></Template>}></Route>
 
       {/* <Route path="*" element={<Template><Home /></Template>}></Route> */}
        <Route path="home" element={<Home />}></Route>
      </Routes>
    );
  } else {
    paths = (
      <Routes>
        <Route exact path="/singin" element={<Login />}></Route>
        <Route path="*" element={<Login />}></Route>
      </Routes>
    );
  }
  return (
    <div>
      {paths}
    </div>
  );
}

export default App;
